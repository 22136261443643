/** @jsx jsx */
import { Link, graphql } from 'gatsby'
import { FC } from 'react'
import { Box, jsx, Flex, Text, Container, Image } from 'theme-ui'
import FrameGame from '../components/framegroup'

import Halo from '../components/halo'
import Layout from '../components/layout'
import Navigation from '../components/navig'

interface FilterCardProps {
  name?: string
  image?: string
}

const FilterCard = ({ name, image }: FilterCardProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        borderBottom: 'solid 1px',
        borderColor: 'grays.1',
        color: 'grays.9',
        fontSize: 1,
        px: 3,
        py: 3,
        '&:hover': { bg: 'grays.1' },
      }}
    >
      <Image src={image} mr={2} />
      <Text variant="blockInfo2" sx={{ fontWeight: 900 }}>
        {name}
      </Text>
    </Box>
  )
}

export interface ContestPage {
  data: any
}

const ContestPage: FC<ContestPage> = ({ data }) => {
  const allMatchesYaml = data.allMatchesYaml.nodes
  return (
    <Layout>
      <Navigation alt={false} />
      <Box variant="box.plateBig" sx={{ pb: 0, pt: 0 }}>
        <Halo title={`Contests | Indelible Ink NFT`} url="https://nft.rage.fan/" />
      </Box>
      <Box sx={{ pt: 7 }}>
        <Container>
          <Flex variant="box.gridly3">
            <Box sx={{ pt: 6, width: ['100%', '70%', '22%'], pl: 4 }}>
              <Text as="h4" variant="blockInfo" sx={{ color: 'grays.4', fontSize: 1, fontWeight: 100, mb: 3 }}>
                Filter by Game
              </Text>
              <Box sx={{ border: 'solid 1px', borderColor: 'grays.1', borderRadius: 5, mr: 5 }}>
                <FilterCard name="Football" image="https://s2.svgbox.net/materialui.svg?ic=sports_soccer&color=white" />
                <FilterCard
                  name="Basketball"
                  image="https://s2.svgbox.net/materialui.svg?ic=sports_basketball&color=white"
                />
                <FilterCard name="Tennis" image="https://s2.svgbox.net/materialui.svg?ic=sports_tennis&color=white" />
                <FilterCard name="Cricket" image="https://s2.svgbox.net/materialui.svg?ic=sports_cricket&color=white" />
              </Box>
            </Box>
            <Box sx={{ flexGrow: 1, pt: 5, width: ['100%', '70%', '65%'], order: 1 }}>
              <Text as="h3" variant="sectionTitleSmall" sx={{ mb: 4}}>
                Upcoming Matches
              </Text>
              <Box bg="#000" sx={{ border: 'solid 1px #222', borderRadius: '8px' }} px={4}>
                {allMatchesYaml &&
                  allMatchesYaml.map((x: any) => (
                    <Link to={`/match/${x.slug}`}>
                      <FrameGame {...x} />
                    </Link>
                  ))}
              </Box>
            </Box>
          </Flex>
        </Container>
      </Box>
    </Layout>
  )
}

export default ContestPage

export const pageQuery = graphql`
  query MyQuery {
    allMatchesYaml {
      nodes {
        name
        match
        date
        desc
        duration
        coin
        slug
        teama
        teama_logo
        teamb
        teamb_logo
        cardImage
        venue
        value
        image
        time
      }
    }
  }
`
